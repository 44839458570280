import { Link } from "gatsby"
import React from "react"
import { Helpers } from "../../services/Helpers"
import { Animal } from "../../models/AnimalModel"

interface Props {
  animal: Animal
}

export default function AnimalCard({ animal }: Props) {
  return (
    <div className="max-w-sm lg:max-w-md rounded overflow-hidden shadow-lg mb-6 mr-0 md:mr-4">
      <Link
        to={"/animal/" + Helpers.sanitizeUrl(animal.animal_name[0].text) + "/"}
        title={animal.animal_name[0].text}
      >
        <div
          style={{
            background: `url(${animal.main_animal_image.url})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: 400,
          }}
        ></div>
      </Link>
      <div className="px-6 py-4 border-b border-lightest h-20">
        <Link
          to={
            "/animal/" + Helpers.sanitizeUrl(animal.animal_name[0].text) + "/"
          }
        >
          <h4 className="mt-0 mb-0">{animal.animal_name[0].text}</h4>
          <p className="text-sm mt-1">{animal.latin_animal_name[0].text}</p>
        </Link>
      </div>
    </div>
  )
}
