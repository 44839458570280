import React from 'react';
import { IoImageOutline } from 'react-icons/io5';

interface Props {
    text: string;
    onClick: any;
    border?: boolean;
    className?: string;
}

export default function DefaultButton({ 
    text,
    onClick,
    border = true,
    className = ''
}: Props) {
  return (
    <button 
        className={`bg-lightest button-text py-2 px-4 rounded inline-flex items-center float-right mr-4 mt-4 ${border ? 'border-theme border-2' : ''} ${className}`}
        onClick={onClick}
    >
        <IoImageOutline
            size={20}
            className="pr-1 mr-1"
        />
        <span className="text-sm">{text}</span>
    </button>
  );
}