import * as React from "react"
import { Description } from "../../models/NationalPark"
import WideContainer from "../layout/WideContainer"
import RichText from "../typography/RichText"

interface Props {
  parkname: string
  mushroomHuntingAllowed: string
  mushroomDescription: Description[]
  campingAllowed: string
  campingDescription: Description[]
}

export default function MushroomAndCampingComponent({
  parkname,
  mushroomHuntingAllowed,
  mushroomDescription,
  campingAllowed,
  campingDescription,
}: Props) {
  const cardMarginLeft =
    "bg-white ml-2 md:ml-20 mr-2 md:mr-4 mt-2 md:mt-6 rounded"
  const cardMarginRight =
    "bg-white mr-2 md:mr-20 ml-2 md:ml-4 mt-2 md:mt-6 rounded"
  const cardPadding = "p-8"

  return (
    <>
      {mushroomHuntingAllowed !== undefined &&
        mushroomDescription !== undefined &&
        mushroomDescription.length > 0 &&
        campingAllowed !== undefined &&
        campingDescription !== undefined &&
        campingDescription.length > 0 && (
          <WideContainer center={false} subClassName="bg-lightest">
            <div className="grid grid-cols-1 md:grid-cols-2 pt-8 pb-12">
              <div className={cardMarginLeft}>
                <div className={cardPadding}>
                  <h4 className="mt-2">Mushroom hunting in {parkname}</h4>
                  <RichText text={mushroomDescription} />
                </div>
              </div>
              <div className={cardMarginRight}>
                <div className={cardPadding}>
                  <h4 className="mt-2">Camping rules in {parkname}</h4>
                  <RichText text={campingDescription} />
                </div>
              </div>
            </div>
          </WideContainer>
        )}
    </>
  )
}
