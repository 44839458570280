import React from "react"
import { Mountain } from "../../models/NationalPark"
import { Helpers } from "../../services/Helpers"
import SimpleInternalButton from "../buttons/SimpleInternalButton"
import MountainCard from "../cards/MountainCard"
import Container from "../layout/Container"
import WideContainer from "../layout/WideContainer"

interface Props {
  title: string
  subtitle: string
  mountains: Mountain[]
  parkname: string
}

const MountainsComponent: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  mountains,
  parkname,
}) => {
  return (
    <>
      {mountains.length > 0 && (
        <WideContainer center={false} className="px-4">
          <SimpleInternalButton
            text={`See all`}
            link={`/${Helpers.sanitizeUrl(parkname)}/mountains/`}
            className="absolute right-28 mt-0 invisible md:visible"
          />
          <h2 className="mb-0 mt-8">{title}</h2>
          <Container center={false} padding={false}>
            <p>{subtitle}</p>
          </Container>
          <div className="grid grid-cols-1 md:grid-cols-3">
            {mountains.map((item, index) => {
              return (
                <MountainCard
                  key={index.toString()}
                  mountain={item}
                  parkname={parkname}
                />
              )
            })}
          </div>
        </WideContainer>
      )}
    </>
  )
}

export default MountainsComponent
