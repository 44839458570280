import * as React from "react"
import { useCallback, useState } from "react"
import ReactBnbGallery from "react-bnb-gallery"
import Gallery from "react-photo-gallery"
import { ImageGallery } from "../../models/NationalPark"
import WideContainer from "../layout/WideContainer"
import AttributionImageListText from "./AttributionImageListText"

interface Props {
  parkname: string
  images: ImageGallery[]
}

const GalleryImages: React.FunctionComponent<Props> = ({
  parkname,
  images,
}) => {
  const [currentImage, setCurrentImage] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setIsOpen(true)
  }, [])

  const galleryImageList = []
  const viewerImageList = []
  const attributionTextList: string[] = []

  images.forEach((element, index) => {
    let galleryImage = {
      src: element.gallery_image.url,
      alt: element.gallery_image.alt,
      width: element.gallery_image.dimensions.width,
      height: element.gallery_image.dimensions.height,
    }
    galleryImageList.push(galleryImage)

    let viewerImage = {
      photo: element.gallery_image.url,
      caption: element.gallery_image.alt,
      subcaption: element.gallery_image.copyright,
    }
    viewerImageList.push(viewerImage)

    attributionTextList.push(element.gallery_image.copyright + " • ")
  })

  return (
    <WideContainer center={false}>
      <h2 className="mb-6 mt-8 px-4">Image gallery of {parkname}</h2>
      <Gallery
        photos={galleryImageList}
        direction={"row"}
        targetRowHeight={350}
        onClick={openLightbox}
      />
      <ReactBnbGallery
        show={isOpen}
        activePhotoIndex={currentImage}
        photos={viewerImageList}
        onClose={() => setIsOpen(false)}
      />
      <AttributionImageListText imageAttribution={attributionTextList} />
    </WideContainer>
  )
}

export default GalleryImages
