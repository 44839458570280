import React, { useState } from 'react'
import ReactBnbGallery from 'react-bnb-gallery';
import { isMobile } from 'react-device-detect';
import { MainParkImage, ImageGallery } from '../../models/NationalPark';
import DefaultButton from '../buttons/DefaultButton';

interface Props {
    main_park_image: MainParkImage;
    image_gallery: ImageGallery[];
}

export default function ImageHeader({ 
    main_park_image, 
    image_gallery
}: Props) {
    const [currentImage, setCurrentImage] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setIsOpen(true);
    };

    const galleryImageList = [];
    const viewerImageList = [];

    let viewerImage = {
        photo: main_park_image.url,
        caption: main_park_image.alt,
        subcaption: main_park_image.copyright,
    }
    viewerImageList.push(viewerImage);

    image_gallery.forEach(element => {

        let galleryImage = {
            src: element.gallery_image.url,
            alt: element.gallery_image.alt,
            width: element.gallery_image.dimensions.width,
            height: element.gallery_image.dimensions.height
        }
        galleryImageList.push(galleryImage);

        let viewerImage = {
            photo: element.gallery_image.url,
            caption: element.gallery_image.alt,
            subcaption: element.gallery_image.copyright,
        }
        viewerImageList.push(viewerImage);
    });

    return (
        <>
            <div className="col-span-12 md:col-span-8 mb-4 md:mb-0">
                <div 
                onClick={() => openLightbox(0)}
                style={{
                background: `url(${main_park_image.url})`,
                cursor: 'pointer',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: isMobile ? 400 : 616,
                marginRight: isMobile ? 0 : 14
                }}
                >  
                </div>
            </div>
            <div className="col-span-12 md:col-span-4">
                <div 
                    onClick={() => openLightbox(1)}
                    style={{
                    background: `url(${image_gallery[0].gallery_image.url})`,
                    cursor: 'pointer',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: 300
                    }}
                >
                    <DefaultButton 
                        text="Show all photos"
                        onClick={() => openLightbox(0)}
                        className="invisible lg:visible"
                    />
                </div>
                <div className="mt-4" 
                onClick={() => openLightbox(2)}
                style={{
                background: `url(${image_gallery[1].gallery_image.url})`,
                cursor: 'pointer',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: 300
                }}></div>
            </div>
            <ReactBnbGallery
                show={isOpen}
                activePhotoIndex={currentImage}
                photos={viewerImageList}
                onClose={() => setIsOpen(false)}
            />
        </>
    );
}