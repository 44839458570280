import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Colors from "../../constants/Colors"
import { FaPaw } from "react-icons/fa"
import WideContainer from "../layout/WideContainer"

interface Props {
  parkname: string
  bear_danger: string
}

export default function BearInfoComponent({ parkname, bear_danger }: Props) {
  return (
    <WideContainer center={false}>
      <div className="mt-4 mb-0 theme-border-bottom theme-border-top">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="md:pr-2">
            <StaticImage
              src="../../images/bear.jpg"
              alt={`Bears in ${parkname}`}
              placeholder="blurred"
            />
          </div>
          <div
            style={{ backgroundColor: Colors.green }}
            className="md:ml-2 md:p-20 p-8 vertical-center"
          >
            <div>
              <h3 style={{ color: Colors.white }} className="mb-4">
                Bears in {parkname}
              </h3>
              {bear_danger === "None" && (
                <>
                  <h6
                    style={{ color: Colors.lightThemeColor }}
                    className="mb-4"
                  >
                    <FaPaw
                      size={20}
                      className="inline ml-0 mr-4 mb-1"
                      color={Colors.grey}
                    />
                    No bears in the park
                  </h6>
                  <p style={{ color: Colors.lightGrey }} className="mt-1 mb-3">
                    This national park has no bears and you do not need to take
                    precautions.
                  </p>
                </>
              )}
              {bear_danger === "Minor" && (
                <>
                  <h6
                    style={{ color: Colors.lightThemeColor }}
                    className="mb-4"
                  >
                    <FaPaw
                      size={20}
                      className="inline ml-0 mr-4 mb-1"
                      color={Colors.grey}
                    />
                    Few bears in the park
                  </h6>
                  <p style={{ color: Colors.lightGrey }} className="mt-1 mb-3">
                    This national park has bears and you need to take the
                    necessary precautions. It is however unlikely that you will
                    come across a bear.
                  </p>
                </>
              )}
              {bear_danger === "Increased" && (
                <>
                  <h6
                    style={{ color: Colors.lightThemeColor }}
                    className="mb-4"
                  >
                    <FaPaw
                      size={20}
                      className="inline ml-0 mr-4 mb-1"
                      color={Colors.grey}
                    />
                    Bears in the park
                  </h6>
                  <p style={{ color: Colors.lightGrey }} className="mt-1 mb-3">
                    This national park has bears and you need to take the
                    necessary precautions. Read the article on bears and seek
                    information from local park authorities.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </WideContainer>
  )
}
