import React from "react"
import Layout from "../components/layout/Layout"
import MetaData from "../components/seo/MetaData"
import { Data } from "../models/StoryblokModel"
import { NationalPark, Node } from "../models/NationalPark"
import Breadcrumbs from "../components/nationalpark/Breadcrumbs"
import CustomCard from "../components/cards/CustomCard"
import RichText from "../components/typography/RichText"
import TagsComponent from "../components/tags/TagsComponent"
import GalleryImages from "../components/nationalpark/GalleryImages"
import SightsComponent from "../components/nationalpark/SightsComponent"
import VisitorCenterComponent from "../components/nationalpark/VisitorCenterComponent"
import MountainsComponent from "../components/nationalpark/MountainsComponent"
import HikeComponent from "../components/nationalpark/HikeComponent"
import NatureSpotComponent from "../components/nationalpark/NatureSpotComponent"
import AttributionImageText from "../components/nationalpark/AttributionImageText"
import ImageHeader from "../components/nationalpark/ImageHeader"
import { BiLinkExternal } from "react-icons/bi"
import Colors from "../constants/Colors"
import BearInfoComponent from "../components/nationalpark/BearInfoComponent"
import MapSection from "../components/maps/MapSection"
import ActivityComponent from "../components/nationalpark/ActivityComponent"
import { Animal } from "../models/AnimalModel"
import AnimalComponent from "../components/nationalpark/AnimalComponent"
import ShelterComponent from "../components/nationalpark/ShelterComponent"
import MushroomComponent from "../components/nationalpark/MushroomComponent"
import ArticleStructuredData from "../components/seo/ArticleStructuredData"

interface Props {
  data: Data
  pageContext: PageContext
  location: any
}

interface PageContext {
  node: Node
  data: NationalPark
  animals: Animal[]
}

const NationalParkTemplate: React.FunctionComponent<Props> = ({
  pageContext,
  location,
}) => {
  return (
    <Layout>
      <MetaData
        title={`${pageContext.data.national_park_name[0].text} - Hiking Trails, Maps, Sights, Information and Wildlife`}
        description={pageContext.data.description[0].text}
        thumbnailUrl={pageContext.data.main_park_image.url}
        imageAlt={`${pageContext.data.main_park_image.alt}`}
        pageUrl={location.pathname}
      />
      <ArticleStructuredData
        headline={pageContext.data.national_park_name[0].text}
        mainImage={pageContext.data.main_park_image}
        imageArray={pageContext.data.image_gallery}
        datePublished={pageContext.node.first_publication_date}
        dateModified={pageContext.node.last_publication_date}
      />
      <div className={`container mx-auto px-4 grid grid-cols-12`}>
        <div className="col-span-12">
          <Breadcrumbs
            continent={pageContext.data.continent}
            country={pageContext.data.country}
            region={pageContext.data.region[0].text}
          />
          <h1 className="mt-2 mb-4">
            {pageContext.data.national_park_name[0].text}
          </h1>
          <TagsComponent tags={pageContext.node?.tags} />
        </div>
        <ImageHeader
          main_park_image={pageContext.data.main_park_image}
          image_gallery={pageContext.data.image_gallery}
        />
        <div className="col-span-12 text-center">
          <AttributionImageText
            imageAttribution={
              pageContext.data.main_park_image.copyright +
              " • " +
              pageContext.data.image_gallery[0].gallery_image.copyright +
              " • " +
              pageContext.data.image_gallery[1].gallery_image.copyright
            }
          />
        </div>
        <div className="col-span-12 md:col-span-8 pl-2 md:pl-6 pr-2 md:pr-10">
          <RichText text={pageContext.data.description} />
          <h3>Getting there</h3>
          <RichText text={pageContext.data.getting_there} />
          <h3>When to visit</h3>
          <RichText text={pageContext.data.when_to_visit} />
        </div>
        <div className="col-span-12 md:col-span-4 pr-6">
          <CustomCard className="pr-1 pl-1 pt-0 mt-4 md:mr-6 mr-2 ml-0 md:ml-0">
            <h4 className="mt-0 mb-3">Details</h4>
            <div className="bg-lightest rounded-sm pt-2 pb-1 px-2">
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm">
                Park website
              </p>
              <a
                href={pageContext.data.official_website.url}
                target="_blank noopener noreferrer"
                className="inline-block mt-0 mb-0 align-text-bottom text-sm float-right w-16 font-bold"
              >
                Link
                <BiLinkExternal
                  size={14}
                  className="inline ml-2"
                  color={Colors.themeColor}
                />
              </a>
            </div>
            <div className="rounded-sm pt-2 pb-1 px-2">
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm">
                Park size
              </p>
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm float-right w-16 font-bold">
                {pageContext.data.park_size} km²
              </p>
            </div>
            <div className="bg-lightest rounded-sm pt-2 pb-1 px-2">
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm">
                Park founded
              </p>
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm float-right w-16 font-bold">
                {pageContext.data.park_founded_year}
              </p>
            </div>
          </CustomCard>
          {pageContext.data.valuable_links.length > 0 && (
            <CustomCard className="pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0 mt-4">
              <h4 className="mt-0 mb-3">Useful links</h4>
              {pageContext.data.valuable_links.map((link, index) => {
                return (
                  <div
                    key={index.toString()}
                    className="rounded-sm pt-2 pb-1 pr-0"
                  >
                    <p className="inline-block mt-0 mb-0 align-text-bottom text-sm">
                      {link.link_name[0].text}
                    </p>
                    <a
                      href={link.link_href.url}
                      target="_blank noopener noreferrer"
                      className="inline-block mt-0 mb-0 align-text-bottom text-sm float-right w-16 font-bold"
                    >
                      Link
                      <BiLinkExternal
                        size={14}
                        className="inline ml-2"
                        color={Colors.themeColor}
                      />
                    </a>
                  </div>
                )
              })}
            </CustomCard>
          )}
        </div>
      </div>
      <MapSection
        parkname={pageContext.data.national_park_name[0].text}
        coordinates={pageContext.data.national_park_coordinates}
      />
      <GalleryImages
        parkname={pageContext.data.national_park_name[0].text}
        images={pageContext.data.image_gallery}
      />
      <SightsComponent
        sights={pageContext.data.sights}
        title={`Sights in ${pageContext.data.national_park_name[0].text}`}
        parkname={pageContext.data.national_park_name[0].text}
        subtitle="Some national parks contain more than just natural wonders. This
                list shows you some of the sights that are located in the
                national park. This includes museums, cathedrals, bridges, dams
                and much more."
      />
      <VisitorCenterComponent
        centers={pageContext.data.visitor_centers}
        title={`Visitor/Information centres in ${pageContext.data.national_park_name[0].text}`}
        parkname={pageContext.data.national_park_name[0].text}
        subtitle="Most national parks have an associated visitor centre to help visitors. 
                            They are usually open most of the year but some close in the off-season.
                            You will often be able to find information on all the activities in the national park.
                            A visit to the visitor centre is often recommended before venturing into the park."
      />
      <HikeComponent
        hikes={pageContext.data.hikes}
        title={`Hiking trails in ${pageContext.data.national_park_name[0].text}`}
        parkname={pageContext.data.national_park_name[0].text}
        subtitle="Almost all national parks have some opportunities for hiking. You
              can see some of the more popular trails here. You should get local
              information for detailed information on all trails of the national
              park."
      />
      <ShelterComponent
        shelters={pageContext.data.shelters}
        title={"Cabins & Shelters"}
        parkname={pageContext.data.national_park_name[0].text}
        subtitle="This section lists some of the cabins and shelters for the national park. They can be great places to stay for the experience or as overnight stays on hikes."
      />
      <NatureSpotComponent
        nature_spots={pageContext.data.nature_spots}
        title={"Natural Sights"}
        parkname={pageContext.data.national_park_name[0].text}
        subtitle="Most national parks have there own trademarks. This goes for both natural sights and man-made. This section will list any popular sights in nature."
      />
      <MountainsComponent
        mountains={pageContext.data.mountains}
        title={`Mountains in ${pageContext.data.national_park_name[0].text}`}
        parkname={pageContext.data.national_park_name[0].text}
        subtitle="This section will list some of the famous peaks in the national
              park. Be sure to do proper research before venturing on to the
              high peaks."
      />
      <ActivityComponent
        activities={pageContext.data.activities}
        title={`Activities in ${pageContext.data.national_park_name[0].text}`}
        parkname={pageContext.data.national_park_name[0].text}
        subtitle="This section will list some of the popular activities in the national
              park. They range from demanding to family type activities."
      />
      <AnimalComponent
        title={`Wildlife in ${pageContext.data.national_park_name[0].text}`}
        subtitle="These sections shows you some of the characteristic wildlife to the national park."
        animals={pageContext.animals}
      />
      <MushroomComponent
        parkname={pageContext.data.national_park_name[0].text}
        mushroomHuntingAllowed={pageContext.data.mushroom_hunting_allowed}
        mushroomDescription={pageContext.data.mushroom_hunting_description}
        campingAllowed={pageContext.data.camping_allowed}
        campingDescription={pageContext.data.camping_description}
      />
      <BearInfoComponent
        parkname={pageContext.data.national_park_name[0].text}
        bear_danger={pageContext.data.bear_danger}
      />
    </Layout>
  )
}

export default NationalParkTemplate
