import React from "react"
import Helmet from "react-helmet"
import Routes from "../../constants/Routes"
import { ImageGallery, MainParkImage } from "../../models/NationalPark"

interface Props {
  headline: string
  mainImage: MainParkImage
  imageArray: ImageGallery[]
  datePublished: string
  dateModified: string
}

const ArticleStructuredData: React.FunctionComponent<Props> = ({
  headline,
  mainImage,
  imageArray,
  datePublished,
  dateModified,
}) => {
  const imageLinkArray: string[] = []
  imageLinkArray.push(mainImage.url)

  imageArray.forEach(element => {
    imageLinkArray.push(element.gallery_image.url)
  })

  const schema = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    headline: headline,
    image: imageLinkArray,
    datePublished: datePublished,
    dateModified: dateModified,
    author: {
      "@type": "Organization",
      url: Routes.SITE_URL,
      name: "Alper",
      logo: `${Routes.SITE_URL}/img/thumbnail.jpg`,
    },
    publisher: {
      "@type": "Organization",
      name: "Alper",
      logo: {
        "@type": "ImageObject",
        url: `${Routes.SITE_URL}/img/thumbnail.jpg`,
      },
    },
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

export default ArticleStructuredData
