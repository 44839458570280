import React from 'react'
import { Helpers } from '../../services/Helpers';
import { Link } from 'gatsby';
import Variables from '../../constants/Variables';

interface Props {
    tags: Array<string>;
}

const TagsComponent: React.FunctionComponent<Props> = ({ tags }) => {
    return (
        <>
            {tags.length > 0 && (
                <>
                    <div className="mb-2">
                        {tags.map( (tag, index) => {
                            return(
                                <Link
                                    style={tagStyles}
                                    key={index} 
                                    className="inline-block pr-4 pl-4 pt-2 pb-2 rounded mr-2 mb-2 bg-light hover:bg-medium" 
                                    to={"/tag/" + Helpers.sanitizeUrl(tag) + "/"} 
                                    title={tag}
                                >
                                    {tag}
                                </Link>
                            );
                        })}
                    </div>
                </>
            )}
        </>
    );
}

const tagStyles = {
    borderWidth: 0,
    borderStyle: 'solid',
    fontSize: 14,
    fontWeight: Variables.fontWeightHeadline
} as React.CSSProperties;

export default TagsComponent;