import React from "react"
import WideContainer from "../layout/WideContainer"
import Container from "../layout/Container"
import AnimalCard from "../cards/AnimalCard"
import { Animal } from "../../models/AnimalModel"

interface Props {
  title: string
  subtitle: string
  animals: Animal[]
}

export default function AnimalComponent({ title, subtitle, animals }: Props) {
  return (
    <>
      {animals.length > 0 && (
        <WideContainer center={false} className="px-4">
          <h2 className="mb-0 mt-8">{title}</h2>
          <Container center={false} padding={false}>
            <p className="mt-2">{subtitle}</p>
          </Container>
          <div className="grid grid-cols-1 md:grid-cols-3 mt-4">
            {animals.map((element, index) => {
              return <AnimalCard key={index.toString()} animal={element} />
            })}
          </div>
        </WideContainer>
      )}
    </>
  )
}
