import React from "react"
import Colors from "../../constants/Colors"
import WideContainer from "../layout/WideContainer"

interface Props {
  imageAttribution: string[]
}

export default function AttributionImageText({ imageAttribution }: Props) {
  return (
    <WideContainer center={true}>
      {imageAttribution.map((item, index) => {
        return (
          <span
            key={index.toString()}
            style={styles}
            className="text-xs gray-100 leading-tight"
          >
            {item}
          </span>
        )
      })}
    </WideContainer>
  )
}

const styles = {
  color: Colors.grey,
  fontSize: 11,
} as React.CSSProperties
