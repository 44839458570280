import React from 'react'
import Colors from '../../constants/Colors';

interface Props {
    imageAttribution: string
}

export default function AttributionImageText({ 
    imageAttribution
}: Props) {
  return (
    <span style={styles} className="text-xs gray-100 leading-tight">{imageAttribution}</span>
  );
}

const styles = {
    color: Colors.grey,
    fontSize: 11
} as React.CSSProperties;